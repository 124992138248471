import dayjs from 'dayjs';
import { IDateRange } from '../interfaces/date-range.interface';
import {
  ConfiguratorDataQueryVariables,
  ProductBundleQueryVariables, ProductPageQueryVariables,
  ProductSearchFilterParams,
  ProductSearchParams,
  ShippingOption,
} from '../@types/codegen/graphql';
import { ILocation } from '../interfaces/location.interface';
import {
  useProductSearchValues,
  IProductSearchFilter, filterValuePartialSelector, manualRadiusSelector,
} from '../store/recoil/product-search';
import { useSetRecoilState } from 'recoil';
import { generateWeekends } from './use-date-info';
import nookies from 'nookies';
import { DigandoCountryCode } from '../constants/cookies';

export interface IPropertyFilter {
  key: string;
  value: string[] | number[];
}

export interface IManufacturer {
  key: string;
  name: string;
}

export interface ISearchTermProps {
  title: string | undefined;
  url: string | undefined;
}

export interface IProductSearchContextProps {
  setProperty: (newProperty: IPropertyFilter) => void;
  setTenantKeys: (tenantKeys: string[]) => void;
  removeTenant: (tenantKey: string) => void;
  removeProperty: (propertyKey: string) => void;
  clearPropertyFilters: () => void;
  resetFilterValues: () => void;
}

const defaultFilterValue: IProductSearchFilter = {
  dateRange: {
    gte: dayjs().add(1, 'day'),
    lte: dayjs().add(20, 'day'),
  },
  geoPoint: null,
  properties: [],
  manufacturers: [], // Use the key from dato instead of the id
  tenantKeys: [], // Use the key from dato instead of the id
  isDeliveryPossible: false, // @todo: Implement to search querie
};

export const generateProductSearchFilterParams = (
  filterValues: IProductSearchFilter,
  uriComponent: string,
  searchQuery?: string,
  searchRadius?: number | null,
): ProductSearchFilterParams => {
  return {
    uriComponent,
    dateRange: {
      gte: dayjs(filterValues.dateRange.gte).format('YYYY-MM-DD'),
      lte: dayjs(filterValues.dateRange.lte).format('YYYY-MM-DD'),
    },
    geoPoint: filterValues.geoPoint,
    searchRadius: searchRadius,
    manufacturers: filterValues.manufacturers.map(manufacturer => manufacturer.key),
    tenantKeys: filterValues.tenantKeys,
    text: searchQuery ?? '',
  };
};

export const generateProductSearchParams = (
  filterValues: IProductSearchFilter,
  cartId: string | null = null,
  uriComponent: string,
  countryCode: string | null,
  currentPageNumber?: number,
  searchQuery?: string,
  searchRadius?: number | null,
): ProductSearchParams => {
  if (null === countryCode) {
    const cookies = nookies.get();
    countryCode = cookies[DigandoCountryCode] ?? null;
  }

  // Remove country code if geoPoint is set. It means when a location search has been added.
  if (null !== filterValues.geoPoint) {
    countryCode = null;
  }

  return {
    ...generateProductSearchFilterParams(filterValues, uriComponent, undefined, searchRadius),
    cartId,
    properties: filterValues.properties,
    pagination: {
      page: currentPageNumber ?? 0,
    },
    countryCode,
    text: searchQuery,
  };
};

export const generateProductBundleVariables = (
  dateRange: IDateRange,
  location: ILocation,
  uriComponent: string,
  variants: IPropertyFilter[] = [],
  variantsRentalStation: IPropertyFilter[] = [],
  rentalStationIdentifier: string | null | undefined,
  tenantKey: string | null,
  deliveryAddressId: string | null,
  cartId?: string | null,
  additionalFilters: Partial<ProductBundleQueryVariables['input']> = {},
  additionalPriceFilters: Partial<ProductBundleQueryVariables['productPriceInput']> = {},
  editBasketItemId?: string,
): ConfiguratorDataQueryVariables & ProductPageQueryVariables => {
  rentalStationIdentifier = (rentalStationIdentifier && ShippingOption.Pickup === additionalFilters.shippingOption) ? rentalStationIdentifier : undefined;

  return {
    input: {
      uriComponent,
      dateRange: {
        start: dateRange.gte.format('YYYY-MM-DD'),
        end: dateRange.lte.format('YYYY-MM-DD'),
      },
      properties: variants,
      rentalStationIdentifier,
      tenantKey,
      deliveryAddressId,
      ...additionalFilters,
      cartId,
      editBasketItemId,
    },
    rentalStationProductBundleInput: {
      uriComponent,
      dateRange: {
        start: dateRange.gte.format('YYYY-MM-DD'),
        end: dateRange.lte.format('YYYY-MM-DD'),
      },
      properties: variantsRentalStation,
      deliveryAddressId,
      rentalStationIdentifier,
      tenantKey,
      accessoryIds: additionalFilters.accessoryIds,
      cartId,
      editBasketItemId,
    },
    fullProductBundleInput: {
      uriComponent,
      dateRange: {
        start: dateRange.gte.format('YYYY-MM-DD'),
        end: dateRange.lte.format('YYYY-MM-DD'),
      },
      tenantKey,
      properties: variants,
      cartId,
      editBasketItemId,
    },
    productPriceInput: {
      uriComponent,
      dateRange: {
        start: dateRange.gte.format('YYYY-MM-DD'),
        end: dateRange.lte.format('YYYY-MM-DD'),
      },
      properties: variants,
      location,
      shippingOption: ShippingOption.Pickup,
      deliveryAddressId,
      rentalStationIdentifier,
      ...additionalPriceFilters,
    },
    location,
    cartId,
  };
};

export const getNextWorkingDay = (
  currentDay: dayjs.Dayjs,
  nonWorkingDays: string[],
  minDay: dayjs.Dayjs,
): dayjs.Dayjs => {
  let nextWorkingDay: dayjs.Dayjs = currentDay;

  if (currentDay.isBefore(minDay)) {
    nextWorkingDay = getNextWorkingDay(minDay, nonWorkingDays, minDay);
  }

  if ((nonWorkingDays.includes(currentDay.format('YYYY-MM-DD')) || (12 <= dayjs().hour() && currentDay.isSame(new Date())))) {
    nextWorkingDay = getNextWorkingDay(currentDay.add(1, 'day'), nonWorkingDays, minDay);
  }

  return nextWorkingDay;
};

export const addBusinessDays = (currentDay: dayjs.Dayjs, nonWorkingDays: string[], add: number): dayjs.Dayjs => {
  let day = currentDay;

  while (0 < (add - 1)) { // Remove 1 day because the startDate is also calculated as a businessday
    day = getNextWorkingDay(day, nonWorkingDays, day.add(1, 'day'));

    add--;
  }

  return day;
};

export const getDefaultFilterValues = (nonWorkingDays: string[], ssrCachedLocation: ILocation | null = null, from: string | null, to: string | null): IProductSearchFilter => {
  const filterValues = { ...defaultFilterValue };

  filterValues.geoPoint = ssrCachedLocation;

  filterValues.dateRange = { ...defaultFilterValue.dateRange };

  const defaultFromDate = getNextWorkingDay(dayjs().add(1, 'day'), nonWorkingDays, dayjs(new Date()));
  const defaultToDate = addBusinessDays(
    filterValues.dateRange.gte,
    nonWorkingDays,
    20,
  );

  filterValues.dateRange.gte = defaultFromDate;
  filterValues.dateRange.lte = getNextWorkingDay(defaultToDate, nonWorkingDays, filterValues.dateRange.gte);

  if (from) {
    const selectedFromDate = getNextWorkingDay(dayjs(`${from}T00:00:00.000Z`), nonWorkingDays, dayjs(new Date()));

    if (selectedFromDate && selectedFromDate.isValid()) {
      filterValues.dateRange.gte = selectedFromDate;
    }
  }

  if (to) {
    const selectedToDate = getNextWorkingDay(dayjs(`${to}T00:00:00.000Z`), nonWorkingDays, filterValues.dateRange.gte);

    if (selectedToDate && selectedToDate.isValid()) {
      filterValues.dateRange.lte = selectedToDate;
    }
  }

  return filterValues;
};

export const useProductSearchHelper = (): IProductSearchContextProps => {
  const { filterValues } = useProductSearchValues();
  const setPartialFilterValues = useSetRecoilState(filterValuePartialSelector);
  const setManualRadiusValue = useSetRecoilState(manualRadiusSelector);

  const removeTenant = (tenantKey: string): void => {
    const newTenantKeys = filterValues.tenantKeys.filter(key => key !== tenantKey);

    setPartialFilterValues({
      tenantKeys: newTenantKeys,
    });
  };

  const removeProperty = (propertyKey: string): void => {
    const newProperties = filterValues.properties.filter(propery => propery.key !== propertyKey);

    setPartialFilterValues({
      properties: newProperties,
    });
  };

  const setProperty = (newProperty: IPropertyFilter): void => {
    const properties = filterValues.properties
      .filter(property => property.key !== newProperty.key);

    properties.push(newProperty);

    // Remove empty values to prevent duplicate loading.
    const filteredProperties = properties.filter(property => property.value && 0 < property.value.length);

    setPartialFilterValues({
      properties: filteredProperties,
    });
  };

  const setTenantKeys = (tenantKeys: string[]): void => {
    setPartialFilterValues({
      tenantKeys,
    });
  };

  const clearPropertyFilters = (): void => {
    if (0 < filterValues.properties.length) {
      setPartialFilterValues({
        properties: [],
      });
    }
  };

  const resetFilterValues = (): void => {
    setPartialFilterValues({
      ...getDefaultFilterValues(generateWeekends(), null, null, null),
      geoPoint: filterValues.geoPoint,
    });

    setManualRadiusValue(null);
  };

  return {
    setTenantKeys,
    setProperty,
    removeTenant,
    removeProperty,
    clearPropertyFilters,
    resetFilterValues,
  };
};

import { FC, useState } from 'react';
import { Icon } from '@digando/react-component-library';
import { WidgetDescription } from '../../styles/widget-description';
import { useTranslation } from 'next-i18next';
import { ProfileItemProps } from './profile-item';
import { LoggedInMenu } from './logged-in-menu';
import { useDetectClickOutside } from 'react-detect-click-outside';
import styles from './profile-item.module.scss';

export const LoggedInProfileItem: FC<ProfileItemProps> = ({ small }) => {
  const [t] = useTranslation(['common']);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const refSelectorContainer = useDetectClickOutside({
    onTriggered: (event): void => {
      // check if a parent contains the id page-header-profile-menu
      if (event.target instanceof HTMLElement && event.target.closest('#page-header-profile-menu')) {
        return;
      }

      setIsOpen(false);
    },
  });

  return (
    <>
      <button
        ref={refSelectorContainer}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Icon icon={'user'} size={'30px'} color={`var(--page-header-font-color)`} />
        <WidgetDescription isHidden={false} className={styles.widgetDescription}>
          {false === small && t('common:my-profile')}
        </WidgetDescription>
      </button>

      <LoggedInMenu isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

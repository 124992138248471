import React, { CSSProperties, FC, useEffect, useState } from 'react';
import styles from './textarea.module.scss';

export interface TextareaProps {
  id: string;
  name: string;
  placeholder: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  value?: string;
  height?: number;
}

export interface MyCustomCSS extends CSSProperties {
  '--ta-height': number;
}

const TextareaComponent: FC<TextareaProps> = (props) => {
  const {
    placeholder,
    name,
    defaultValue = '',
    id,
    onChange,
    value,
    height = 2,
  } = props;
  const [internalValue, setInternalValue] = useState<string>(defaultValue);

  useEffect(() => {
    if (value && value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  return (
    <div className={styles.textareaContainer}>
      <style>
        {`
          textarea {
            --ta-height: ${height};
          }
        `}
      </style>
      <textarea
        name={name}
        placeholder={placeholder}
        id={id}
        value={internalValue}
        onChange={(event): void => {
          const newValue = event.target.value;

          setInternalValue(newValue);
          onChange(newValue);
        }}
      />
    </div>
  );
};

export const Textarea = React.memo(TextareaComponent, (prevProps, nextProps) => {
  return prevProps.id === nextProps.id;
});
